import gsap from 'gsap'
import * as utils from './utils'

export function rive() {
  const rive = document.getElementById('rive')
  rive.setAttribute('class', '')
  gsap.set('#rive', {
    clearProps: 'all'
  })
}

export function SCDOM(SC) {
  gsap.set('#character canvas', {
    clearProps: 'all'
  })
}

export function SCRive(SC) {
  utils.nullify([
    SC.eyebrowType,
    SC.eyeType,
    SC.mouthType,
    SC.armType,
    SC.jumpType,
    SC.soundType,
    SC.bubbleType
  ])
  utils.falsify([
    SC.isOutlined,
    SC.isUpMore
  ])
  gsap.set([SC.translateX, SC.translateY], {
    value: 25
  })
}

export function floor() {
  gsap.set('#floor', {
    clearProps: 'all'
  })
}