import gsap from 'gsap'

function findRadius() {
  const w = document.body.clientWidth
  const h = document.body.clientHeight
  const x = w/2
  const y = h
  const dx = x < w / 2 ? w - x : x
  const dy = y < h / 2 ? h - y : y
  const radius = Math.sqrt(dx * dx + dy * dy)
  return radius
}

export default class TransitionOverlay {
	constructor() {
		this.rive = document.getElementById('rive')
		this.character = document.getElementById('character')
		this.canvas = document.getElementById('transition-overlay')
		this.ctx = this.canvas.getContext('2d')
    this.w = document.body.clientWidth
    this.h = document.body.clientHeight
		this.overlay = {
			r: 0
		}

		this.isClipping = false
		this.runTL = false

		this.init()
		this.resize()
		this.animate()
	}

	init() {
		this.draw()
	}

	draw() {
    this.w = document.body.clientWidth
    this.h = document.body.clientHeight
		this.canvas.width = this.w
		this.canvas.height = this.h

		if(this.isClipping) {
			this.ctx.fillStyle =  '#f67499'
		} else {
			this.ctx.fillStyle = 'rgba(255,255,255,0)'
		}
		this.ctx.fillRect(0, 0, this.w, this.h)

		this.ctx.beginPath()
		this.ctx.arc(this.w/2, this.h, this.overlay.r, 0, Math.PI * 2)
		this.ctx.globalCompositeOperation = 'xor'
		if(this.isClipping) {
			this.ctx.fillStyle = 'blue'
		} else {
			this.ctx.fillStyle =  '#f67499'
		}
		this.ctx.fill()
	}

	resize() {
		window.addEventListener('resize', () => {
			this.draw()
		})
	}

	animate() {
		this.ctx.clearRect(0, 0, this.w, this.h)
		this.draw()
		requestAnimationFrame(this.animate.bind(this))
	}

	basic(data, SC, isSCHidden, done) {

		if(!isSCHidden) {
			gsap.set('#character', { zIndex: 1 })
		} else {
			gsap.set('#character', { zIndex: 'unset' })
		}

		const tl = gsap.timeline({
      defaults: {
        duration: .5,
        ease: 'power2.out'
      },
			onStart: () => {
				this.isClipping = false
				this.overlay.r = 0	

				if(isSCHidden && data.next.namespace == 'about') {
					gsap.delayedCall(.25, () => { SC.soundType.value = 101 })
				}

				if(isSCHidden && data.next.namespace == 'work') {
					SC.soundType.value = 102
				}
			},
      onComplete: () => {
				this.isClipping = false
				this.overlay.r = 0	
				this.character.setAttribute('style', '')
				
				if(data.current.namespace == 'about') {
					gsap.from('#character canvas', {
						opacity: 0,
						duration: .5,
						ease: 'power2.inOut'
					})
				}

				done()
			}
		})

		tl
			.to(this.overlay, {
        r: () => {
          const radius = findRadius()
          return radius
        },
        ease: 'power2.in',
        onComplete: () => {
					window.scrollTo(0, 0)

          const section = data.current.container.querySelector('section')
          if(section) {
            gsap.set(section, {
							opacity: 0,
							pointerEvents: 'none'
            })
          } else {
						console.log('no section found')
					}

          if(data.current.namespace == 'home') {
						this.rive.classList.add('show-sc-only')
          }

          if(isSCHidden) {
						this.character.style.display = 'none'
          }
        }
      })
      .set('main', {
        backgroundColor: 'var(--color-peach)',
        onComplete: () => {
          this.isClipping = true
        },
      })
			.set(this.overlay, {
				r: 0
			})
			.to(this.overlay, {
				r: () => {
					const radius = findRadius()
					return radius
				}
			})
	}

	toHome(done) {
    const tl = gsap.timeline({
			onStart: () => {
				this.isClipping = false
				this.overlay.r = 0
			},
      onComplete: () => done()
    })
  
    gsap.set('#character', { zIndex: 'unset' })
  
    tl
      .to(this.overlay, {
        r: () => {
          const radius = findRadius()
          return radius
        },
				duration: .65,
        ease: 'power2.inOut'
      })
	}

	open() {
		const tl = gsap.timeline({
			onStart: () => {
				this.isClipping = true
				this.overlay.r = 0
			},
			onComplete: () => {
				this.isClipping = false
				this.overlay.r = 0	
			}
		})
		tl
      .to(this.overlay, {
        r: () => {
          const radius = findRadius()
          return radius
        }
      })
	}
}