import gsap from 'gsap'

export function menu(namespace) {
  const items = gsap.utils.toArray('.menu-list a')
  const activeItem = document.querySelector('.menu-list a.is-active')
  let url = ''

  switch(namespace) {
    case 'home':
      url = '/'
      break
    case 'about':
      url = '/about'
      break
    case 'work':
      url = '/work'
      break
  }

  activeItem.classList.remove('is-active')
  items.forEach(item => {
    const itemURL = new URL(item.href)
    if(itemURL.pathname == url) {
      item.classList.add('is-active')
    }
  })
}

export function video() {
  var cards = [].slice.call(document.querySelectorAll('.card'))

  if ('IntersectionObserver' in window) {
    var cardObserver = new IntersectionObserver(function(entries) {
      entries.forEach(function(card) {
        if (card.isIntersecting) {
          const video = card.target.querySelector('video')
          const videoSource = card.target.querySelector('source')

          videoSource.src = videoSource.dataset.src
          video.load()
          video.onloadeddata = function() {
            card.target.classList.add('is-loaded')
          }
          cardObserver.unobserve(card.target)
        }
      })
    })

    cards.forEach(function(card) {
      cardObserver.observe(card)
    })
  }
}

export function editMode(SC) {
  const mainTitle = document.getElementById('main-title')
  const leftArtboards = gsap.utils.toArray('#desk, #about-sc')
  const rightArtboards = gsap.utils.toArray('#music, #social-resume')
  const trigger = document.getElementById('edit-trigger')

  trigger.addEventListener('click', () => {
    SC.isMoving.value = false

    gsap.to([SC.translateX, SC.translateY], {
      value: 25
    })
    gsap.delayedCall(.5, () => {
      SC.armType.value = 3
      SC.isFlying.value = true
    })
  
    const tl = gsap.timeline({
      defaults: {
        duration: .75,
        ease: 'expo.inOut'
      }
    })
    tl
      .set([leftArtboards, rightArtboards], {
        pointerEvents: 'none'
      })
      .to(mainTitle, {
        opacity: 0,
        y: '-50%'
      })
      .to('#rive .inner', {
        width: '30%'
      }, 0)
      .to('#rive #floor', {
        left: 0,
        right: 0
      }, 0)
      .to([leftArtboards, rightArtboards], {
        opacity: 0,
      }, 0)
  })
}