import gsap from 'gsap'
import * as utils from './utils'

class Jump {
  constructor(SC) {
    this.SC = SC
  }

  addListener() {
    this.controller = new AbortController()
    document.addEventListener('keydown', e => {
      this.jump(e)
    }, { signal: this.controller.signal })
  }

  jump(e) {
    if(e.keyCode == 32) {
      this.SC.jumpType.value = 1
      this.SC.soundType.value = 1001
      this.SC.armType.value = 3
      this.SC.mouthType.value = 2
      this.SC.showBubble.value = false
    }
  }

  removeListener() {
    this.controller.abort()
  }
}

class Menu {
  constructor(SC) {
    this.state = false
    this.menu = document.querySelector('menu')
    this.trigger = document.querySelector('.menu-trigger')
    this.SC = SC
    this.run()
  }

  run() {
    this.trigger.addEventListener('click', () => {
      this.state = !this.state
      if(this.state) {
        this.SC.soundType.value = 201
        this.menu.classList.add('is-active')
      } else {
        this.SC.soundType.value = 202
        this.menu.classList.remove('is-active')
      }
    })
  }

  close() {
    this.SC.soundType.value = 0
    this.menu.classList.remove('is-active')
    this.state = false
  }
}

class Shirt {
  constructor(SC) {
    this.SC = SC
    this.shirtCurrent = 0
    this.shirtLength = 4
    this.trigger = document.getElementById('shirt-trigger')
    this.set()
    this.addListener()
  }

  addListener() {
    this.trigger.addEventListener('mouseleave', () => {
      this.leave()
    })
    this.trigger.addEventListener('mouseenter', () => {
      this.enter()
    })
    this.trigger.addEventListener('click', () => {
      this.change()
    })
  }

  change() {
    this.SC.showBubble.value = false
    this.SC.isBounce.value = true
    this.SC.sparkType.value = 1
    this.SC.playSpark.value = true
    this.SC.soundType.value = 2
    this.SC.armType.value = 3

    gsap.delayedCall(.1, () => {
      this.SC.soundType.value = 0
    })

    if(this.shirtCurrent < this.shirtLength) {
      this.shirtCurrent++
    } else {
      this.shirtCurrent = 0
    }

    this.SC.shirtType.value = this.shirtCurrent
    localStorage.setItem('SCShirt', this.shirtCurrent)
  }

  set() {
    if(localStorage.getItem('SCShirt')) {
      this.shirtCurrent = localStorage.getItem('SCShirt')
    }

    const search = window.location.search
    if(search) {
      const urlParams = new URLSearchParams(search)
      const shirtParam = urlParams.get('shirt')
      switch (shirtParam) {
        case 'rive':
          this.shirtCurrent = 2
          break
        case 'vercel':
          this.shirtCurrent = 4
          break
      }
    }

    this.SC.shirtType.value = this.shirtCurrent
  }

  enter() {
    this.SC.sparkType.value = 2
    this.SC.playSpark.value = true
    this.SC.isBounce.value = true
    this.SC.showBubble.value = true
    this.SC.bubbleType.value = 7
    this.SC.eyebrowType.value = 1
    this.SC.eyeType.value = 1
    this.SC.mouthType.value = 4
    this.SC.soundType.value = 3
  }
  
  leave() {
    utils.falsify([
      this.SC.showBubble
    ])
    utils.nullify([
      this.SC.soundType,
      this.SC.eyeType,
      this.SC.eyebrowType,
      this.SC.mouthType,
      this.SC.armType
    ])
  }
}

export {
  Jump,
  Menu,
  Shirt
}