import gsap from 'gsap'
import * as utils from '../utils'

export default class About {
  constructor(inputs) {
    this.inputs = inputs
    this.triggers = gsap.utils.toArray('.about-trigger')
  }

  addListener() {
    this.controller = new AbortController()
    this.triggers.forEach(trigger => {
      trigger.addEventListener('mouseleave', () => {
        this.leave()
      }, { signal: this.controller.signal })
      trigger.addEventListener('mouseenter', () => {
        this.enter()
      }, { signal: this.controller.signal })
    })
  }

  enter() {
    this.inputs.Sumatrancat.soundType.value = 2
    utils.truefy([
      this.inputs.AboutSC.isPosterHovered,
      this.inputs.Sumatrancat.isBounce,
      this.inputs.Sumatrancat.showBubble
    ])
    this.inputs.Sumatrancat.soundType.value = 2
    this.inputs.Sumatrancat.mouthType.value = 3
    this.inputs.Sumatrancat.eyeType.value = 3
    this.inputs.Sumatrancat.eyebrowType.value = 1
    this.inputs.Sumatrancat.armType.value = 1
    this.inputs.Sumatrancat.bubbleType.value = 6
  }
  
  leave() {
    utils.falsify([
      this.inputs.AboutSC.isPosterHovered,
      this.inputs.Sumatrancat.isBounce,
      this.inputs.Sumatrancat.showBubble
    ])
    utils.nullify([
      this.inputs.Sumatrancat.eyeType,
      this.inputs.Sumatrancat.soundType,
      this.inputs.Sumatrancat.mouthType,
      this.inputs.Sumatrancat.eyebrowType,
      this.inputs.Sumatrancat.armType
    ])
  }

  removeListener() {
    // remove all listeners
    this.controller.abort()

    // this SC animation happens here
    this.inputs.AboutSC.isPosterHovered.value = false
    this.inputs.Sumatrancat.armType.value = 4
    this.inputs.Sumatrancat.isMoving.value = false
    this.inputs.Sumatrancat.showBubble.value = false
    this.inputs.Sumatrancat.jumpType.value = 2
    this.inputs.Sumatrancat.eyebrowType.value = 1
    this.inputs.Sumatrancat.eyeType.value = 4
    this.inputs.Sumatrancat.mouthType.value = 4
    this.inputs.Sumatrancat.soundType.value = 1001

    gsap.delayedCall(.5, () => {
      this.inputs.Sumatrancat.soundType.value = 0
      this.inputs.Sumatrancat.soundType.value = 101
    })
  }
}