import gsap from 'gsap'
import { isMobile } from './utils'

export default class Mouse {
	constructor(SC) {
		this.SC = SC
		this.body = document.body
		this.ease = .5

		this.pos = this.mouse = {
			x: window.innerWidth/2,
			y: window.innerHeight/2
		}
		this.posRive = { x: 0, y: 0 }

		window.addEventListener('mousemove', (e) => {
			if(!isMobile()) this.move(e)
		})

		// window.addEventListener('touchmove', (e) => {
		// 	this.move(e.targetTouches[0])
		// })
		this.update()
	}

	move(e) {
		this.mouse = {
			x: e.clientX,
			y: e.clientY
		}
	}

	update(e) {
		const rangeX = gsap.utils.mapRange(0, window.innerWidth, 0, 50)
		const rangeY = gsap.utils.mapRange(0, window.innerHeight, 50, 0)

		this.pos.x += (this.mouse.x - this.pos.x) * this.ease
		this.pos.y += (this.mouse.y - this.pos.y) * this.ease

		this.posRive = {
			x: rangeX(this.pos.x),
			y: rangeY(this.pos.y)
		}

		// set cursor position to character's SC
		if(this.SC.isMoving.value && this.mouse.x !== this.pos.x) {
			this.SC.translateX.value = this.posRive.x
			this.SC.translateY.value = this.posRive.y
		}

		requestAnimationFrame(this.update.bind(this))
	}

	findPos() {
		return {
			x: this.posRive.x,
			y: this.posRive.y
		}
	}
}