import gsap from 'gsap'
import * as utils from './../utils'

export function lookAtCamera(SC) {
  SC.isMoving.value = false
  gsap.to(SC.translateX, {
    value: 25,
    duration: .2,
    ease: 'power2.out'
  })
  gsap.to(SC.translateY, {
    value: 25,
    duration: .2,
    ease: 'power2.out'
  })
}

export function lookAway(SC, pos) {
  gsap.to(SC.translateX, {
    value: pos.x,
    duration: .1,
    ease: 'power2.out'
  })
  gsap.to(SC.translateY, {
    value: pos.y,
    duration: .1,
    ease: 'power2.out'
  })
  gsap.delayedCall(.1, () => {
    SC.isMoving.value = true
  })
}

export function jumpBig(SC) {
  const tl = gsap.timeline()
  tl
    .to(SC.translateX, {
      value: 25,
      duration: .2,
      ease: 'power2.out'
    }, 0)
    .to(SC.translateY, {
      value: 0,
      duration: .2,
      ease: 'power2.out'
    }, 0)
    .to('#floor', {
      scaleX: 0,
      transformOrigin: 'center'
    })
}

export function jumpComplete(SC) {
  SC.jumpType.value = 0
  utils.nullify([
    SC.soundType,
    SC.armType,
    SC.mouthType
  ])
}







/* ===== not used anymore ===== */
export function marquee() {
  gsap.to('#marquee .part', {
    xPercent: -100,
    repeat: -1,
    duration: 10,
    ease: 'linear'
  }).totalProgress(0.5)

  gsap.from('#marquee .inner', {
    opacity: 0,
    duration: .5,
    ease: 'power2.in'
  })
}

export function homeTitle() {
  const $ = gsap.utils.selector('#home-title')
  CustomEase.create('easeInOutCirc', '.85, 0, .15, 1')

  // light animation
  const tl = gsap.timeline({})
  tl
    .set('use', {
      delay: .3,
      display: 'block'
    })
    .to($('#clip path'), {
      x: 650,
      duration: 1.5,
      ease: 'easeInOutCirc',
      repeat: -1,
      repeatDelay: 1
    })
    .to($('#stars path'), {
      opacity: 1,
      duration: .75,
      stagger: .1,
      ease: 'power2.out'
    }, 1)
  
  gsap.to($('#stagger-text path'), {
    delay: .4,
    opacity: 1,
    y: 0,
    stagger: .035,
    ease: 'power2.out',
    onComplete: () => {
      gsap.set($('#main-text'), {
        display: 'block'
      }) 
    }
  })

  gsap.to('#stars path', {
    rotate: 360,
    duration: 5,
    ease: 'linear',
    repeat: -1
  })

  animateStar('path:first-child', .5)
  animateStar('path:nth-child(2)', 1.75)

  function animateStar(selector, delay) {
    gsap.to($('#stars ' + selector), {
      delay: delay,
      scale: 1,
      repeat: -1,
      repeatDelay: .5,
      yoyo: true,
      duration: 1,
    })
  }
}