import gsap from 'gsap'

function findRadius() {
  const w = document.body.clientWidth
  const h = document.body.clientHeight
  const x = w/2
  const y = h
  const dx = x < w / 2 ? w - x : x
  const dy = y < h / 2 ? h - y : y
  const radius = Math.sqrt(dx * dx + dy * dy)
  return radius
}

export default class MainOverlay {
	constructor() {
		this.canvas = document.getElementById('main-overlay')
		this.ctx = this.canvas.getContext('2d')
    this.w = document.body.clientWidth
    this.h = document.body.clientHeight
		this.overlay = {
			x: this.w/2,
			y: this.h,
			r: 0
		}

		this.init()
		this.resize()
		this.animate()
	}

	init() {
		this.draw()
	}

	draw() {
		this.w = document.body.clientWidth
		this.h = document.body.clientHeight
		this.canvas.width = this.w
		this.canvas.height = this.h

		this.ctx.fillStyle =  '#f67499'
		this.ctx.fillRect(0, 0, this.w, this.h)

		this.ctx.beginPath()
		this.ctx.arc(this.overlay.x, this.overlay.y, this.overlay.r, 0, Math.PI * 2)
		this.ctx.globalCompositeOperation = 'xor'
		this.ctx.fillStyle = 'blue'
		this.ctx.fill()
	}

	resize() {
		window.addEventListener('resize', () => {
			this.draw()
		})
	}

	animate() {
		this.ctx.clearRect(0, 0, this.w, this.h)
		this.draw()
		requestAnimationFrame(this.animate.bind(this))
	}

	open(callback) {
		const tl = gsap.timeline({
			onComplete: () => {
				this.overlay.r = 0
        gsap.set('#loader', {
          display: 'none'
        })
				gsap.set('body', {
					backgroundColor: 'var(--color-peach)'
				})
				callback()
			}
		})
		tl
			.to('#loader .spinner', {
				opacity: 0,
				duration: .5,
				onComplete: () => {
					document.body.classList.remove('is-loading')
				}
			})
			.to(this.overlay, {
				r: () => {
					const radius = findRadius()
					return radius
				},
				duration: .75,
				ease: 'expo.out',
			})
	}
}