import gsap from 'gsap'
import * as utils from '../utils'

export default class Work {
  constructor(inputs) {
    this.inputs = inputs
    this.triggers = gsap.utils.toArray('.work-trigger')
  }

  addListener() {
    this.controller = new AbortController()
    this.triggers.forEach(trigger => {
      trigger.addEventListener('mouseleave', () => {
        this.leave()
      }, { signal: this.controller.signal })
      trigger.addEventListener('mouseenter', () => {
        this.enter()
      }, { signal: this.controller.signal })
    })
  }

  enter() {
    utils.truefy([
      this.inputs.Desk.isMonitorHovered,
      this.inputs.Sumatrancat.isBounce,
      this.inputs.Sumatrancat.showBubble
    ])
    this.inputs.Sumatrancat.soundType.value = 2
    this.inputs.Sumatrancat.bubbleType.value = 0
    this.inputs.Sumatrancat.mouthType.value = 4
    this.inputs.Sumatrancat.armType.value = 5
    this.inputs.Sumatrancat.eyebrowType.value = 1
  }
  
  leave() {
    utils.falsify([
      this.inputs.Desk.isMonitorHovered,
      this.inputs.Sumatrancat.isBounce,
      this.inputs.Sumatrancat.showBubble
    ])
    utils.nullify([
      this.inputs.Sumatrancat.soundType,
      this.inputs.Sumatrancat.mouthType,
      this.inputs.Sumatrancat.armType,
      this.inputs.Sumatrancat.eyeType,
      this.inputs.Sumatrancat.eyebrowType,
    ])
  }

  removeListener() {
    // remove all listeners
    this.controller.abort()
    this.inputs.Sumatrancat.armType.value = 3
    this.inputs.Sumatrancat.mouthType.value = 2
    this.inputs.Sumatrancat.eyebrowType.value = 1
    this.inputs.Sumatrancat.showBubble.value = false
    this.inputs.Sumatrancat.isMoving.value = false
    this.inputs.Sumatrancat.soundType.value = 102
  }
}