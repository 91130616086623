export function isMobile(){
  // credit to Timothy Huang for this regex test: 
  // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    return true
  } else {
    return false
  }
}

export function setCursorPointer() {
  document.body.style.cursor = 'pointer'
}

export function setCursorDefault() {
  document.body.style.cursor = 'unset'
}

export function truefy(selectors) {
  selectors.forEach(sel => {
    sel.value = true
  })
}

export function falsify(selectors) {
  selectors.forEach(sel => {
    sel.value = false
  })
}

export function nullify(selectors) {
  selectors.forEach(sel => {
    sel.value = 0
  })
}

export function random(numbers) {
  return numbers[Math.floor(Math.random()*numbers.length)];
}