import gsap from 'gsap'
import { Flip } from 'gsap/all'
import { isMobile } from './utils'
gsap.registerPlugin(Flip)

export function fromHomeToWork(inputs) {
  const tl = gsap.timeline({
    defaults: {
      duration: .5,
      ease: 'power2.out'
    }
  })

  const props = {
    y: '46%',
    scale: 1.3
  }

  if(isMobile()) {
    props.y = '100%'
  }

  tl
    .to([inputs.Sumatrancat.translateX, inputs.Sumatrancat.translateY], {
      value: 25
    }, 0)
    .to('#rive', {
      duration: 1,
      paddingBottom: 0,
      ease: 'expo.inOut',
    }, 0)
    .to('#character', {
      duration: 1,
      scale: props.scale,
      y: props.y,
      ease: 'expo.inOut'
    }, 0)
}



export function enterHome(inputs) {
  const $ = gsap.utils.selector('#loader')
  const tl = gsap.timeline({
    defaults: {
      duration: .5,
      ease: 'power2.out'
    }
  })
  
  inputs.Sumatrancat.soundType.value = 103
  inputs.Sumatrancat.isZoomReady.value = false
  inputs.Sumatrancat.isZoom.value = false
  inputs.Sumatrancat.armType.value = 4.5
  inputs.Sumatrancat.translateX.value = 25
  inputs.Sumatrancat.translateY.value = 0
  inputs.Sumatrancat.eyebrowType.value = 1
  inputs.Sumatrancat.mouthType.value = 2
  inputs.Sumatrancat.isOutlined.value = false
  inputs.Sumatrancat.isUpMore.value = false

  tl
    .set('#character', {
      clearProps: 'all'
    })
    .set('#floor', {
      scaleX: 0,
    })
    .set('#character', {
      zIndex: 1,
    })
    .to('#floor', {
      scaleX: 1
    })
    .from('#character canvas', {
      onStart: () => {
        inputs.Sumatrancat.jumpType.value = 3
      },
      scaleY: 1.25,
      y: () => {
        const h = window.innerHeight
        const ch = document.getElementById('character').clientHeight
        const y = h - ch - (0.1 * h)
        return -y - ch + 'px'
      },
      duration: .3
    }, '<')
}


export function enterWork(SC, artboards) {
  SC.eyebrowType.value = 0
  SC.eyeType.value = 0
  SC.mouthType.value = 0
  Object.keys(artboards).forEach(objectName => {
    if(objectName !== 'Sumatrancat') {
      artboards[objectName].pause()
    }
  })

  if(isMobile()) {
    gsap.set('#character', {
      scale: 1.3,
      y: '100%'
    })
  } else {
    gsap.set('#character', {
      clearProps: 'all'
    })
  }
}



export function enterAbout(SC) {
  const $ = gsap.utils.selector('#about')
  const wrapper = document.getElementById('rive')
  const state = Flip.getState('#rive, #character > canvas')
  wrapper.classList.add('is-ready')

  Flip.from(state, {
    absolute: true,
    duration: .5,
    ease: 'power2.inOut',
  })

  gsap.to($('.header, .content'), {
    delay: .5,
    y: 0,
    opacity: 1,
    duration: .5,
    stagger: .1,
    ease: 'power2.out'
  })
}