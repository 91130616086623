import { Howl, Howler } from 'howler'

export default class Music {
  constructor() {
    this.audio = new Howl({
      src: ['music/kk.mp3'],
      sprite: {
        clip: [8200, 222300]
      },
      autoplay: false,
      loop: true,
      volume: .1
    })
  }

  play() {
    this.audio.play('clip')
  }

  stop() {
    this.audio.stop('clip')
  }
}