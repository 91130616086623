import gsap from 'gsap'
import barba from '@barba/core'

import Mouse from './mouse'
import Music from './music'

import * as hover from './hover/hover'
import HoverLook from './hover/look'
import HoverWork from './hover/work'
import HoverAbout from './hover/about'

import MainOverlay from './overlay/main'
import TransitionOverlay from './overlay/transition'

import { Menu, Jump, Shirt } from './interactions'

import * as utils from './utils'
import * as setup from './setup'
import * as reset from './reset'
import * as transitions from './transitions'
import * as animations from './animations/animations'
import * as views from './views'

const rive = require('@rive-app/canvas')
const riveFile = new URL('../rive/sumatrancat.riv', import.meta.url)

// app
class App {
  constructor() {
    this.isTransitioning = false
    this.isSCHidden = true
    this.mainOverlay = new MainOverlay()
    this.transitionOverlay = new TransitionOverlay()
  }

  storage() {
    if(!localStorage.getItem('SCShirt')) {
      localStorage.setItem('SCShirt', 0)
    }
  }

  loadArtboards() {
    this.numberOfLoadedArtboards = 0
    this.artboardNames = ['Sumatrancat', 'Desk', 'Music', 'SocialResume', 'AboutSC']
    this.artboardSelectors = ['sc', 'desk', 'music', 'social-resume', 'about-sc']
    this.userInputs = {
      'Sumatrancat': [
        'isLoaded', 'isBlinking', 'isUpMore', 'isXMore', 'isMoving', 'isBounce',
        'isMasked', 'isMaskedLoaded', 'isOutlined', 'isZoomReady', 'isZoom',
        'translateX', 'translateY', 'showBubble', 'playSpark', 'isFlying',
        'eyebrowType', 'eyeType', 'mouthType', 'armType', 'jumpType', 'soundType', 'bubbleType', 'shirtType', 'sparkType'
      ],
      'Desk': ['isLoaded', 'isReady', 'reset', 'isMonitorHovered'],
      'Music': ['isLoaded', 'isReady', 'reset', 'isSoundOn', 'isGuitarHovered'],
      'SocialResume': ['isLoaded', 'isReady', 'reset',
        'isTwitterHovered', 'isYoutubeHovered', 'isGithubHovered', 'isResumeHovered'],
      'AboutSC': ['isLoaded', 'isReady', 'reset', 'isPosterHovered']
    }
    this.artboards = {}
    this.artboardInputs = {}

    this.artboardNames.forEach((name, i) => {
      // insert blank object to inputs object
      this.artboardInputs[name] = {}
      this.artboards[name] = new rive.Rive({
        src: riveFile,
        artboard: name,
        stateMachines: 'SM',
        canvas: document.querySelector(`.artboard-${this.artboardSelectors[i]}`),
        onLoad: () => {
          this.artboards[name].resizeDrawingSurfaceToCanvas()
          this.artboards[name].play()
          this.artboardLoaded()
        }
      })       
    })
  }

  artboardLoaded() {
    this.numberOfLoadedArtboards +=1
    if(this.numberOfLoadedArtboards == this.artboardNames.length) {
      this.assignInputs()
      this.events()
      this.barba()
      this.menu = new Menu(this.artboardInputs.Sumatrancat)
      this.jump = new Jump(this.artboardInputs.Sumatrancat)
      this.shirt = new Shirt(this.artboardInputs.Sumatrancat)
      this.storage()

      if(!utils.isMobile()) this.music.play()
      this.mainOverlay.open(() => {
        console.log('done')
      })
    }
  }

  assignInputs() {
    // repeat through user inputs and assign them to the artboard inputs object
    Object.keys(this.userInputs).forEach(objectName => {
      this.artboardInputs[objectName] = this.artboards[objectName].stateMachineInputs('SM')
      this.userInputs[objectName].forEach(input => {
        this.artboardInputs[objectName][input] = this.artboardInputs[objectName].find(i => i.name === input)
      })
    })

    this.mouse = new Mouse(this.artboardInputs.Sumatrancat)
    this.music = new Music()

    this.hoverLook = new HoverLook(this.artboardInputs.Sumatrancat, this.mouse)
    this.hoverWork = new HoverWork(this.artboardInputs)
    this.hoverAbout = new HoverAbout(this.artboardInputs)

    hover.social(this.artboardInputs, this.mouse)
    hover.resume(this.artboardInputs)
    hover.menu(this.artboardInputs.Sumatrancat)
  }

  events() {
    let faceIsHovered = false

    /* ===== EVENT SUMATRANCAT ===== */
    this.artboards.Sumatrancat.on(rive.EventType.RiveEvent, e => {
      const data = e.data

      switch(data.name) {
        case 'sparkComplete':
          this.artboardInputs.Sumatrancat.playSpark.value = false
          break

        case 'landSmall':
          this.artboardInputs.Sumatrancat.sparkType.value = 0
          this.artboardInputs.Sumatrancat.playSpark.value = true
          break

        case 'landMid':
          this.artboardInputs.Sumatrancat.sparkType.value = 0
          this.artboardInputs.Sumatrancat.playSpark.value = true
          this.artboardInputs.Sumatrancat.eyeType.value = 4
          this.artboardInputs.Sumatrancat.armType.value = 3

          this.artboardInputs.Music.isReady.value = false
          this.artboardInputs.Desk.isReady.value = false
          this.artboardInputs.AboutSC.isReady.value = false
          this.artboardInputs.SocialResume.isReady.value = false
          
          Object.keys(this.artboards).forEach(objectName => {
            if(objectName !== 'Sumatrancat') {
              this.artboards[objectName].play()
            }
          })

          this.artboardInputs.Music.reset.fire()
          this.artboardInputs.Desk.reset.fire()
          this.artboardInputs.SocialResume.reset.fire()
          this.artboardInputs.AboutSC.reset.fire()

          this.transitionOverlay.open()
          break

        case 'landComplete':
          utils.nullify([
            this.artboardInputs.Sumatrancat.armType,
            this.artboardInputs.Sumatrancat.eyeType,
            this.artboardInputs.Sumatrancat.eyebrowType,
            this.artboardInputs.Sumatrancat.mouthType,
            this.artboardInputs.Sumatrancat.jumpType
          ])
          gsap.to(this.artboardInputs.Sumatrancat.translateY, {
            value: 25
          })
          break

        case 'jumpComplete':
          animations.jumpComplete(this.artboardInputs.Sumatrancat)
          break

        case 'jumpMidAir':
          const tl = gsap.timeline({
            onComplete: () => {
              utils.nullify([
                this.artboardInputs.Sumatrancat.jumpType,
                this.artboardInputs.Sumatrancat.eyeType,
                this.artboardInputs.Sumatrancat.mouthType,
                this.artboardInputs.Sumatrancat.armType,
              ])
              this.artboardInputs.Sumatrancat.translateX.value = 25
              this.artboardInputs.Sumatrancat.translateY.value = 25
              this.artboardInputs.Sumatrancat.isMasked.value = true
            }
          })

          tl
            .to('#character canvas', {
              y: '133%',
              scaleY: 1.2,
              transformOrigin: 'bottom',
              ease: 'power4.in',
              duration: .5
            })
          break

        case 'bounceComplete':
          this.artboardInputs.Sumatrancat.isBounce.value = false
          break

        case 'faceEnter':
          if(!faceIsHovered) {
            faceIsHovered = true
            this.artboardInputs.Sumatrancat.isBounce.value = true
            this.artboardInputs.Sumatrancat.sparkType.value = 2
            this.artboardInputs.Sumatrancat.playSpark.value = true
          } else {
            this.artboardInputs.Sumatrancat.isBounce.value = false
          }
          this.artboardInputs.Sumatrancat.eyebrowType.value = 1
          this.artboardInputs.Sumatrancat.soundType.value = 1
          this.artboardInputs.Sumatrancat.mouthType.value = 2
          this.artboardInputs.Sumatrancat.armType.value = 3

          const main = document.querySelector('main')
          this.artboardInputs.Sumatrancat.showBubble.value = true
          if(main.getAttribute('data-barba-namespace') == 'home') {
            this.artboardInputs.Sumatrancat.bubbleType.value = 9
          } else {
            this.artboardInputs.Sumatrancat.bubbleType.value = 1
          }

          break

        case 'faceLeave':
          faceIsHovered = false
          this.artboardInputs.Sumatrancat.showBubble.value = false
          utils.nullify([
            this.artboardInputs.Sumatrancat.soundType,
            this.artboardInputs.Sumatrancat.armType,
            this.artboardInputs.Sumatrancat.mouthType,
            this.artboardInputs.Sumatrancat.eyebrowType
          ])
          break
      }
    })




    /* ===== EVENT DESK ===== */
    this.artboards.Desk.on(rive.EventType.RiveEvent, e => {
      const data = e.data
      if(data.name == 'loadComplete') {
        this.artboardInputs.Desk.isReady.value = true
      }
    })




    /* ===== EVENT ABOUT SC ===== */
    this.artboards.AboutSC.on(rive.EventType.RiveEvent, e => {
      const data = e.data
      if(data.name == 'loadComplete') {
        this.artboardInputs.AboutSC.isReady.value = true
      }
    })




    /* ===== EVENT SOCIAL RESUME ===== */
    this.artboards.SocialResume.on(rive.EventType.RiveEvent, e => {
      const data = e.data
      switch(data.name) {
        case 'loadComplete':
          this.artboardInputs.SocialResume.isReady.value = true
          break
      }
    })

    


    /* ===== EVENT MUSIC ===== */
    this.artboards.Music.on(rive.EventType.RiveEvent, e => {
      const data = e.data
      switch(data.name) {

        case 'loadComplete':
          this.artboardInputs.Music.isReady.value = true
          break

        case 'guitarEnter':
          this.artboardInputs.Sumatrancat.isBounce.value = true
          this.artboardInputs.Sumatrancat.showBubble.value = true
          this.artboardInputs.Sumatrancat.soundType.value = 1
          this.artboardInputs.Sumatrancat.eyeType.value = 2
          this.artboardInputs.Sumatrancat.armType.value = 5
          this.artboardInputs.Sumatrancat.eyebrowType.value = 1
          this.artboardInputs.Sumatrancat.mouthType.value = 1
          this.artboardInputs.Sumatrancat.bubbleType.value = 3
          animations.lookAtCamera(this.artboardInputs.Sumatrancat)
          break

        case 'guitarLeave':
          utils.nullify([
            this.artboardInputs.Sumatrancat.soundType,
            this.artboardInputs.Sumatrancat.eyeType,
            this.artboardInputs.Sumatrancat.armType,
            this.artboardInputs.Sumatrancat.eyebrowType,
            this.artboardInputs.Sumatrancat.mouthType,
            this.artboardInputs.Sumatrancat.armType
          ])
          this.artboardInputs.Sumatrancat.showBubble.value = false
          animations.lookAway(this.artboardInputs.Sumatrancat, this.mouse.findPos())
          break

        case 'flowerEnter':
          utils.truefy([
            this.artboardInputs.Sumatrancat.showBubble,
            this.artboardInputs.Sumatrancat.isBounce
          ])
          this.artboardInputs.Sumatrancat.eyeType.value = 1
          this.artboardInputs.Sumatrancat.eyebrowType.value = 1
          this.artboardInputs.Sumatrancat.armType.value = 3
          this.artboardInputs.Sumatrancat.mouthType.value = 4
          this.artboardInputs.Sumatrancat.bubbleType.value = 5
          this.artboardInputs.Sumatrancat.soundType.value = 1
          animations.lookAtCamera(this.artboardInputs.Sumatrancat)
          break

        case 'flowerLeave':
          utils.nullify([
            this.artboardInputs.Sumatrancat.eyebrowType,
            this.artboardInputs.Sumatrancat.mouthType,
            this.artboardInputs.Sumatrancat.soundType,
            this.artboardInputs.Sumatrancat.armType,
            this.artboardInputs.Sumatrancat.eyeType
          ])
          this.artboardInputs.Sumatrancat.showBubble.value = false
          break

        case 'homepodEnter':
          utils.truefy([
            this.artboardInputs.Sumatrancat.showBubble,
            this.artboardInputs.Sumatrancat.isBounce
          ])
          this.artboardInputs.Sumatrancat.soundType.value = 3
          this.artboardInputs.Sumatrancat.mouthType.value = 2
          this.artboardInputs.Sumatrancat.eyebrowType.value = 1
          this.artboardInputs.Sumatrancat.armType.value = 1
          this.artboardInputs.Sumatrancat.bubbleType.value = 1002
          animations.lookAtCamera(this.artboardInputs.Sumatrancat)
          utils.setCursorPointer()
          break

        case 'homepodLeave':
          utils.falsify([
            this.artboardInputs.Sumatrancat.isBounce,
            this.artboardInputs.Sumatrancat.showBubble
          ])
          utils.nullify([
            this.artboardInputs.Sumatrancat.soundType,
            this.artboardInputs.Sumatrancat.mouthType,
            this.artboardInputs.Sumatrancat.eyebrowType,
            this.artboardInputs.Sumatrancat.armType
          ])
          utils.setCursorDefault()
          break

        case 'socialLeave':
          animations.lookAway(this.artboardInputs.Sumatrancat, this.mouse.findPos())
          break

        default:
          break

      }
    })
  }






  /* ===== BARBA ===== */
  barba() {
    const that = this
    const artboards = this.artboards
    const inputs = this.artboardInputs
    const transitionOverlay = this.transitionOverlay

    const rive = document.getElementById('rive')
    const character = document.getElementById('character')

    barba.hooks.leave((data) => {
      that.isTransitioning = true
    })

    barba.hooks.beforeLeave((data) => {
      this.menu.close()
      this.jump.removeListener()

      inputs.Sumatrancat.isMoving.value = false

      if(data.current.namespace == 'home' && data.next.namespace == 'work') {
        this.hoverLook.removeListener()
        this.hoverWork.removeListener()
      }

      if(data.current.namespace == 'home' && data.next.namespace == 'about') {
        this.hoverLook.removeListener()
        this.hoverAbout.removeListener()
        animations.jumpBig(inputs.Sumatrancat)
      }
    })

    barba.hooks.beforeEnter((data) => {
      // enable camera hover on all pages
      if(data.next.namespace == 'home') {
        this.hoverLook.addListener()
        this.jump.addListener()
      }
      
      if(data.next.namespace !== 'work') {
        inputs.Sumatrancat.isZoom.value = false
        inputs.Sumatrancat.isZoomReady.value = false
      }

      if(data.next.namespace !== 'about') {
        rive.classList.remove('is-ready')
        inputs.Sumatrancat.isMasked.value = false
        inputs.Sumatrancat.isMaskedLoaded.value = false
      }
    })

    barba.hooks.afterEnter((data) => {
      views.menu(data.next.namespace)

      // enable hovers on homepage only
      if(data.next.namespace == 'home') {
        this.hoverAbout.addListener()
        this.hoverWork.addListener()
      }
    })

    barba.init({
      transitions: [
        {
          name: 'default',
          leave(data) {
            const done = this.async()
            transitionOverlay.basic(data, inputs.Sumatrancat, true, done)
          }
        },
        {
          name: 'back-to-home',
          from: {
            namespace: ['about', 'work']
          },
          to: {
            namespace: ['home']
          },
          leave(data) {
            const done = this.async()
            transitionOverlay.toHome(done)
          },
          enter() {
            transitions.enterHome(inputs)
          }
        },
        {
          name: 'home-work',
          from: {
            namespace: ['home']
          },
          to: {
            namespace: ['work']
          },
          beforeLeave() {
            transitions.fromHomeToWork(inputs, artboards)
          },
          leave(data) {
            const done = this.async()
            transitionOverlay.basic(data, inputs.Sumatrancat, false, done)
          },
          enter() {
            transitions.enterWork(inputs.Sumatrancat, artboards)
          }
        },
        {
          name: 'home-about',
          from: {
            namespace: ['home']
          },
          to: {
            namespace: ['about']
          },
          leave(data) {
            const done = this.async()
            gsap.delayedCall(.5, () => {
              transitionOverlay.basic(data, inputs.Sumatrancat, false, done)
            })
          }
        }
      ],
      views: [
        {
          namespace: 'home',
          beforeEnter() {
            document.body.classList.add('overflow-is-hidden')
            reset.rive()
            reset.floor()
          },
          afterEnter() {
            // hover.email(inputs)
            gsap.delayedCall(1, () => {
              inputs.Sumatrancat.isMoving.value = true
              inputs.Sumatrancat.isBlinking.value = true
            })
            gsap.delayedCall(.5, () => {
              inputs.Music.isLoaded.value = true
              inputs.Sumatrancat.isLoaded.value = true
              inputs.SocialResume.isLoaded.value = true
              inputs.Desk.isLoaded.value = true
              inputs.AboutSC.isLoaded.value = true
            })
          }
        },
        {
          namespace: 'about',
          beforeEnter(data) {
            reset.SCRive(inputs.Sumatrancat)
            reset.SCDOM()
            setup.onlySC()
            setup.about(data, artboards)
            character.style.display = 'none'
          },
          afterEnter() {
            hover.name(inputs)
            inputs.Sumatrancat.isMoving.value = false

            let maskedDelay = 0
            let mainDelay = 0

            if(that.isTransitioning) {
              maskedDelay = .1
              mainDelay = .5
            } else {
              maskedDelay = .6
              mainDelay = 1.1
            }
            gsap.delayedCall(maskedDelay, () => {
              character.setAttribute('style', '')
              // rive needs a slight delay in order to run this particular timeline correctly
              inputs.Sumatrancat.isMasked.value = true
              inputs.Sumatrancat.isMaskedLoaded.value = true
            })
            gsap.delayedCall(mainDelay, () => {
              transitions.enterAbout(artboards.Sumatrancat)
            })

          }
        },
        {
          namespace: 'work',
          beforeEnter() {
            setup.onlySC()
            setup.work(inputs, artboards)
            hover.cards(inputs.Sumatrancat)
            views.video()
          },
          afterEnter () {
            inputs.Sumatrancat.isMoving.value = true
            let delay = 0

            if(that.isTransitioning) {
              delay = 0
            } else {
              delay = .75
            }

            gsap.from('#work .card', {
              delay: delay,
              y: 30,
              opacity: 0,
              stagger: .05,
              ease: 'power2.out'
            })
          }
        }
      ]
    })
  }

}

setup.menu()
const app = new App()

document.addEventListener('DOMContentLoaded', () => {
  // load all artboards when DOM is loaded
  app.loadArtboards()
})