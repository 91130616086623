import gsap from 'gsap'
import * as utils from '../utils'
import * as animations from '../animations/animations'

export function cards(SC) {
  const triggers = gsap.utils.toArray('#work .card')

  if(!utils.isMobile() && triggers.length > 0) {
    triggers.forEach((trigger, index) => {

      trigger.addEventListener('mouseenter', function() {
        triggers.map((childTrigger, childIndex) => {
          if(index != childIndex) {
            childTrigger.classList.add('is-dim')
          }
        })

        if(this.nodeName == 'A') {
          SC.soundType.value = 2
          SC.eyebrowType.value = 1
          SC.mouthType.value = 4
        } else {
          SC.soundType.value = 3
          SC.eyebrowType.value = 1
          const random = utils.random([2,3])
          SC.mouthType.value = random
        }

        SC.isBounce.value = true
        SC.bubbleType.value = 4

        gsap.delayedCall(.1, () => {
          SC.soundType.value = 0
        })
      })

      trigger.addEventListener('mouseleave', () => {
        utils.nullify([
          SC.soundType,
          SC.eyebrowType,
          SC.mouthType
        ])

        triggers.map((childTrigger) => {
          childTrigger.classList.remove('is-dim')
        })
      })
    })
  }
}

export function resume(inputs) {
  const triggers = gsap.utils.toArray('.resume-trigger')
  if(triggers.length > 0) {
    triggers.forEach(trigger => {
      trigger.addEventListener('mouseenter', () => {
        utils.truefy([
          inputs.SocialResume.isResumeHovered,
          inputs.Sumatrancat.isBounce,
          inputs.Sumatrancat.showBubble
        ])
        inputs.Sumatrancat.soundType.value = 2
        inputs.Sumatrancat.mouthType.value = 4
        inputs.Sumatrancat.eyeType.value = 2
        inputs.Sumatrancat.eyebrowType.value = 1
        inputs.Sumatrancat.armType.value = 1
        inputs.Sumatrancat.bubbleType.value = 4
      })
      trigger.addEventListener('mouseleave', () => {
        inputs.Sumatrancat.soundType.value = 0
        utils.falsify([
          inputs.SocialResume.isResumeHovered,
          inputs.Sumatrancat.isBounce,
          inputs.Sumatrancat.showBubble
        ])
        utils.nullify([
          inputs.Sumatrancat.mouthType,
          inputs.Sumatrancat.eyeType,
          inputs.Sumatrancat.eyebrowType,
          inputs.Sumatrancat.armType
        ])
      })
    })
  }
}

export function social(inputs, mouse) {
  const parent = document.querySelector('.social-links')
  const links = gsap.utils.toArray('.social-links a')

  parent.addEventListener('mouseleave', () => {
    const pos = mouse.findPos()
    animations.lookAway(inputs.Sumatrancat, pos)
  })

  links.forEach(link => {
    const type = link.dataset.social
    link.addEventListener('mouseenter', () => {
      animations.lookAtCamera(inputs.Sumatrancat)
      inputs.Sumatrancat.soundType.value = 3
      utils.truefy([
        inputs.SocialResume[`is${type}Hovered`],
        inputs.Sumatrancat.isBounce,
        inputs.Sumatrancat.showBubble
      ])
      switch(type) {
        case 'Twitter':
          inputs.Sumatrancat.bubbleType.value = 101
          inputs.Sumatrancat.eyebrowType.value = 1
          inputs.Sumatrancat.eyeType.value = 1
          inputs.Sumatrancat.mouthType.value = 1
          inputs.Sumatrancat.armType.value = 2
          break
        case 'Youtube':
          inputs.Sumatrancat.bubbleType.value = 102
          inputs.Sumatrancat.eyebrowType.value = 2
          inputs.Sumatrancat.eyeType.value = 4
          inputs.Sumatrancat.mouthType.value = 2
          inputs.Sumatrancat.armType.value = 5
          break
        case 'Github':
          inputs.Sumatrancat.bubbleType.value = 103
          inputs.Sumatrancat.eyebrowType.value = 2
          inputs.Sumatrancat.eyeType.value = 2
          inputs.Sumatrancat.mouthType.value = 4
          inputs.Sumatrancat.armType.value = 2
          break
      }
    })
    link.addEventListener('mouseleave', () => {
      inputs.Sumatrancat.soundType.value = 0
      utils.falsify([
        inputs.SocialResume[`is${type}Hovered`],
        inputs.Sumatrancat.isBounce,
        inputs.Sumatrancat.showBubble
      ])
      utils.nullify([
        inputs.Sumatrancat.eyeType,
        inputs.Sumatrancat.eyebrowType,
        inputs.Sumatrancat.mouthType,
        inputs.Sumatrancat.armType
      ])
    })
  })
}

export function sound(inputs) {
  const triggers = gsap.utils.toArray('.sound-trigger')
  if(triggers.length > 0) {
    triggers.forEach(trigger => {
      trigger.addEventListener('mouseenter', () => {
        animations.lookAtCamera(inputs.Sumatrancat)
        utils.truefy([
          inputs.Sumatrancat.isBounce,
          inputs.Sumatrancat.showBubble
        ])
        inputs.Sumatrancat.soundType.value = 2
        inputs.Sumatrancat.mouthType.value = 2
        inputs.Sumatrancat.eyebrowType.value = 1
        inputs.Sumatrancat.armType.value = 1
        inputs.Sumatrancat.bubbleType.value = 1002
      })
      trigger.addEventListener('mouseleave', () => {
        inputs.Sumatrancat.soundType.value = 0
        utils.falsify([
          inputs.Sumatrancat.isBounce,
          inputs.Sumatrancat.showBubble
        ])
        utils.nullify([
          inputs.Sumatrancat.mouthType,
          inputs.Sumatrancat.eyebrowType,
          inputs.Sumatrancat.armType
        ])
      })
    })
  }
}

export function name(inputs) {
  const trigger = document.querySelector('.name-trigger')
  trigger.addEventListener('mouseenter', () => {
    utils.truefy([
      inputs.Sumatrancat.isBounce,
      inputs.Sumatrancat.showBubble
    ])
    inputs.Sumatrancat.soundType.value = 1
    inputs.Sumatrancat.mouthType.value = 3
    inputs.Sumatrancat.eyeType.value = 2
    inputs.Sumatrancat.eyebrowType.value = 1
    inputs.Sumatrancat.bubbleType.value = 201
  })
  trigger.addEventListener('mouseleave', () => {
    inputs.Sumatrancat.soundType.value = 0
    utils.falsify([
      inputs.Sumatrancat.isBounce,
      inputs.Sumatrancat.showBubble
    ])
    utils.nullify([
      inputs.Sumatrancat.mouthType,
      inputs.Sumatrancat.eyeType,
      inputs.Sumatrancat.eyebrowType
    ])
  })
}

export function email(inputs) {
  const trigger = document.querySelector('.email-trigger')
  trigger.addEventListener('mouseenter', () => {
    utils.truefy([
      inputs.Sumatrancat.isBounce,
      inputs.Sumatrancat.showBubble
    ])
    inputs.Sumatrancat.bubbleType.value = 8
    inputs.Sumatrancat.soundType.value = 2
    inputs.Sumatrancat.eyebrowType.value = 2
    inputs.Sumatrancat.mouthType.value = 2
    inputs.Sumatrancat.armType.value = 2
  })
  trigger.addEventListener('mouseleave', () => {
    inputs.Sumatrancat.soundType.value = 0
    utils.falsify([
      inputs.Sumatrancat.isBounce,
      inputs.Sumatrancat.showBubble
    ])
    utils.nullify([
      inputs.Sumatrancat.mouthType,
      inputs.Sumatrancat.eyeType,
      inputs.Sumatrancat.eyebrowType,
      inputs.Sumatrancat.armType
    ])
  })
}

export function menu(SC) {
  const triggers = gsap.utils.toArray('.menu-trigger, li a')
  const menu = document.querySelector('menu')

  if(!utils.isMobile() && triggers.length > 0) {
    triggers.forEach((trigger, index) => {

      trigger.addEventListener('mouseenter', function() {
        if(this.classList.contains('menu-trigger') && !menu.classList.contains('is-active')) {
          SC.soundType.value = 1
        }
        if(this.nodeName == 'A'){
          SC.soundType.value = 3
        }
        gsap.delayedCall(.1, () => {
          SC.soundType.value = 0
        })
      })

      trigger.addEventListener('mouseleave', () => {
        SC.soundType.value = 0
      })
    })
  }
}