import gsap from 'gsap'
import { isMobile } from './utils'

export function menu() {
  const items = gsap.utils.toArray('.menu-list a')
  items.forEach(item => {
    const itemURL = new URL(item.href)
    if(itemURL.pathname == window.location.pathname) {
      item.classList.add('is-active')
    }
  })
}

export function onlySC() {
  const rive = document.getElementById('rive')
  rive.classList.add('show-sc-only')
}

export function work(inputs, artboards) {
  document.body.classList.remove('overflow-is-hidden')
  inputs.Sumatrancat.isOutlined.value = true

  gsap.set('#rive', {
    paddingBottom: 0
  })
  gsap.set('#floor', {
    display: 'none'
  })

  if(isMobile()) {
    gsap.set('#character', {
      scale: 1.3,
      y: '100%'
    })
  } else {
    inputs.Sumatrancat.isZoomReady.value = true
    inputs.Sumatrancat.isZoom.value = true
    inputs.Sumatrancat.isUpMore.value = true
    artboards.Sumatrancat.resizeDrawingSurfaceToCanvas()
  }
}

export function about(data, artboards) {
  document.body.classList.remove('overflow-is-hidden')
  artboards.Sumatrancat.resizeDrawingSurfaceToCanvas()

  const container = data.next.container
  let els = container.querySelectorAll('.header, .content')
  if(els) {
    els = gsap.utils.toArray(els)
    gsap.set(els, {
      y: 30,
      opacity: 0
    })
  }
}