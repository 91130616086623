import gsap from 'gsap'
import * as animations from '../animations/animations'

export default class Look {
  constructor(SC, mouse) {
    this.SC = SC
    this.mouse = mouse
    this.triggers = gsap.utils.toArray('.look-trigger')
  }

  addListener() {
    this.controller = new AbortController()
    this.triggers.forEach(trigger => {
      trigger.addEventListener('mouseleave', () => {
        this.leave()
      }, { signal: this.controller.signal })
      trigger.addEventListener('mouseenter', () => {
        this.enter()
      }, { signal: this.controller.signal })
    })
  }

  enter() {
    animations.lookAtCamera(this.SC)
  }
  
  leave() {
    const pos = this.mouse.findPos()
    animations.lookAway(this.SC, pos)
  }

  removeListener() {
    this.controller.abort()
  }
}